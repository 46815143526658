import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Firebase from 'firebase'
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import 'vue-blu/dist/css/vue-blu.min.css'

const firebaseConfig = {
    apiKey: "AIzaSyAZnNgIvOQzlswuPx0yHlV64SCQM7E054M",
    authDomain: "standup-newways.firebaseapp.com",
    projectId: "standup-newways",
    storageBucket: "standup-newways.appspot.com",
    messagingSenderId: "103816210221",
    appId: "1:103816210221:web:60b424bc7ba9b6ec6e55a4",
    measurementId: "G-5TYDVWHWCY"
};

let firebase = Firebase.initializeApp(firebaseConfig);
export const fireauth = firebase.auth();
export const firestore = firebase.firestore();
export const firestorage = firebase.storage();


createApp(App)
    .use(router)
    .use(VueSplide)
    .mount('#app')
