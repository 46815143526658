<template>
  <div class="hello">
    <div style="margin-bottom: 24px;">
      <splide 
        :options="splideOptions"
        @splide:moved="onChanged"
        >
        <splide-slide
          class="mb-5"
          v-for="image of images" 
          :key="image">
          <div class='char-border' :class="currActive(image)">
            <img class="d-block w-100" :src="image.url" :alt="image.alt">
          </div>
        </splide-slide>
      </splide>
      <!-- <div class="story">
        <img src="../assets/images/main_01.png" alt="여의도 국회의사당 앞, 돗자리 부대가 속출하고 있다는 뉴스 화면이 보인다. 뉴스 속보입니다. 여의도 국회의사당 앞에 드러 눕는 돗자리 부대가 속출하고 있습니다. 누워 있는 모습도 제각각인데요. 왜 여기에 누워 계신 건지 물어 봤습니다. 참가자의 말입니다. 누울 자리 보고 발 뻗으라는데 도무지 누울 자리가 없어서요. 날씨도 좋은데 같이 누워 보실래요?">
      </div> -->
    </div>
    <div class="wrap-button">
      <button class="fixed-bottom main-next-button" @click="nextPage">
        나도 일어나러 가기
      </button>
    </div>
  </div>
</template>

<script>
import { data } from '../data'

export default {
  name: 'MainView',
  methods: {
    currActive: function (image) {
      return image.index == this.currIndex ? 'active' : ''
    },
    nextPage: function () {
      this.$router.push('/select-char')
    }
  },
  data: () => {
    return {
      images: data.main,
      splideOptions: {
        gap    : '15px',
        arrows : false,
        // width : '100%',
        fixedwidth : '6rem',
        // autoWidth : true,
        padding: {
          // top: '1rem',
          right: '2rem',
          left : '2rem',
        },
      },
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.char-border {
  padding-top: 4px;
}

.hello {
  position: relative;
  background: #EFEFEF;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
}

@media screen and (min-width: 36em) {
  .hello {
    position: absolute;
    background: #EFEFEF;
    width: 22.5em;
    height: 40em;
    left: 50%;
    top: 50%;
    margin-right: auto;
    margin-left: auto;
    transform: translate(-50%, -50%);
  }
}
 
.wrap-button {
  background: #EFEFEF;
  padding: 0px 0px 32px 0px;
}

.main-next-button {
  text-align: center;
  padding: 16px;

  margin: 16px;
  font-size: 16px;
  font-weight: bold;
  background: #000;
  color: #fff;
  border-radius: 8px;
  height: 56px;
}

.story img{
  width: 100%;
  margin-bottom: 0px;
  background: #EFEFEF;
}

/* .story img.last{
  padding-bottom: 100px;
} */
</style>
