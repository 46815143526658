<template>
  <div class="hello">
    <div class="desc">
      인스타그램 '국회의사당' <br>장소 태그 점령하기
    </div>

    <div class="save-image-desc">
      이미지를 꾹 눌러 저장하세요
    </div>
    <div class="">
      <div class="my-char">
        <img :src="imageData" alt="내가 누워 있는 모습이 보이고 있어요"/>
      </div>      
    </div>

    <div class="step" style="">
      <div class="share-desc px-4">
        <div>1. 이미지를 저장하고 해시태그를 복사한다.</div>
        <div>2. 인스타그램 피드에 해시태그와 함께 업로드한다.</div>
        <div>3. 업로드 위치를 ‘국회의사당’으로 지정한다.</div>
        <div>4. 국회의사당에 다같이 서기 완료!</div>
      </div>
    </div>

    <div class="mx-3" style="margin-top: 10px;">
      <div class="row">
        <div class="hash-text ms-3 col border border-dark">
          #일어나정치해야지 #눈알감시단 #총선 #뉴웨이즈
        </div>
        <div class="copy-btn me-3 col-3 btn-dark d-flex justify-content-center" style="background-color: black; cursor: pointer;">
          <div style="align-self: center;" @click="copyText">
            <div class="align-middle text-white fw-bold">
              복사하기
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="why-button" @click="gotoNewways">
      <!-- 진짜 중요한 '선거법 눈알 감시단' 보러가기 -->
      <!-- 진짜 중요한 선거법 감시단 되기 👀 -->
      <!-- 선거법 👀 감시단 되기 -->
      <!-- 진짜 중요한 선거법 👀 감시단 되기 -->
      우리가 일어나야 하는 진짜 이유
    </button>
    
    <!-- <div class="space">
    </div> -->
    
    <div class="">
      <div class="share-text">
        답답한 친구에게도 공유하러 가기
      </div>
      <div class="share-icon">
        <button class="share-button" @click="share">
          공유하기
        </button>
        <button class="archive-button" @click="gotoArchive">
          서있는 사람들 다시 보기
        </button>

      </div>
      <div class="logo">
        <img src="../assets/images/logo3.png" alt="뉴웨이즈 로고"/>
      </div>
    </div>

    <div class="real-footer">
      <div class="fw-bold text-white">
        만든 사람
      </div>
      <div class="creator">
        <div class="">
          <a href="https://newways.kr/?utm_source=homepage&utm_medium=landing&utm_campaign=standup&utm_content=231127" target="_blank">기획: 뉴웨이즈</a>
        </div>
        <div class="">
          <a href="https://www.instagram.com/takecareez" target="_blank">캐릭터 디자인: EZMONSTER </a>
        </div>
        <div class="">
          <a href="https://www.linkedin.com/in/yong-sang-lee-63236a35/" target="_blank">페이지 개발: 이용상</a>
        </div>
        <div class="">
          <a href="https://www.nosidestudio.com" target="_blank">캠페인 파트너: 노사이드</a>
        </div>
      </div>
      <div class="support">
        <!-- 이 웹사이트는 청년허브의 &lt;청년업&gt; 사업 지원을 받았습니다 -->
      </div>
    </div>

    <div class="center p-0 " 
      style="z-index: 5; text-align: -webkit-center; position: sticky; bottom: 16px;">
      <div class="toast hide align-items-center center text-white border-0" 
      style="background-color: black; width: 160px; max-width: 160px;"
      role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body text-white">
            링크가 복사 되었습니다
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>

    <div class="center p-0 " 
      style="z-index: 5; text-align: -webkit-center; position: sticky; bottom: 16px;">
      <div class="toast hide align-items-center center text-white border-0" 
      style="background-color: black; width: 180px; max-width: 180px;"
      role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body text-white">
            해시태그가 복사 되었습니다
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>

    <section v-if="showModal">
      <PopupModal :closeBtn="false" @before-close="beforeCloseModal">
      </PopupModal>
    </section>
  </div>
</template>

<script>
/*eslint no-undef: "off"*/
import { Toast } from 'bootstrap'
import { data } from '../data'
import PopupModal from './components/PopupModal'

export default {
  name: 'ArchiveView',
  components: {
    PopupModal
  },
  data: () => {
    return {
      imageData: localStorage.imageData,
      toastItem: null,
      hashToastItem: null,
      showModal: true,
    }
  },
  mounted: async function () {
    const toastElList = [].slice.call(document.querySelectorAll('.toast'))
    const toastList = toastElList.map(function (toastEl) {
      return new Toast(toastEl, {delay: 2000})
    })

    this.toastItem = toastList[0]
    this.hashToastItem = toastList[1]

    const imageData = await this.createImage();
    this.imageData = imageData;

    console.log('mounted')

    this.showModal = false
  },
  methods: {
    createImage: async function () {
      const imageSize = 2048;
      const canvasSize = 300;
      let canvas = new fabric.Canvas('canvas', {
          isDrawingMode: false,
          width: canvasSize,
          height: canvasSize,
          allowTouchScrolling: false,
      });

      const backIndex = +localStorage.selectBackground
      const charIndex = +localStorage.selectChar
      const objectIndex = +localStorage.selectObject

      let imageStatus = 0
      fabric.Image.fromURL(data.grass[0].url, (grass) => {
          grass.scaleX = canvasSize / imageSize
          grass.scaleY = canvasSize / imageSize
          grass.left = 0
          grass.top = 0
          canvas.add(grass);
          imageStatus = 1
          console.log('finish grass')
          fabric.Image.fromURL(data.image[backIndex].url, (back) => {
              back.scaleX = canvasSize / imageSize
              back.scaleY = canvasSize / imageSize
              back.left = 0
              back.top = 0
              canvas.add(back);
              console.log('finish back')
              imageStatus = 2

              fabric.Image.fromURL(data.char[charIndex].url, (char) => {
                  char.scaleX = canvasSize / imageSize
                  char.scaleY = canvasSize / imageSize
                  char.left = 0
                  char.top = 0
                  canvas.add(char);
                  console.log('finish char')
                  imageStatus = 3

                  fabric.Image.fromURL(data.placard[objectIndex].url, (ob) => {
                      // console.log('ob', ob)
                      ob.scaleX = (canvasSize / imageSize)
                      ob.scaleY = (canvasSize / imageSize)
                      // ob.left = (canvasSize / 2) - (ob.width * ob.scaleX / 2)
                      // ob.top = (canvasSize / 2) - (ob.height * ob.scaleY / 2)
                      char.left = 0
                      char.top = 0
                      canvas.add(ob);
                      console.log('finish ob')
                      imageStatus = 4
                      setTimeout(() => {
                        imageStatus = 5
                      }, 10)
                  })
              })
          })
      })

      console.log('imageStatus', imageStatus)

      // return new Promise(resolve => setTimeout(() => {
      //   console.log('imageStatus', imageStatus)
      //   const data = canvas.toDataURL({
      //       format: 'png',
      //       multiplier: 2.5,
      //   })
      //   console.log('data', data.length)
      //   resolve(data)
      // }, 3000));

      return new Promise((resolve) => {
        (function waitForDataCompletion() {
          if (imageStatus == 5) { // 'dataIsReady'는 데이터가 준비되었는지 확인하는 함수여야 합니다.
            console.log('imageStatus', imageStatus)
            const data = canvas.toDataURL({
                format: 'png',
                multiplier: 2.5,
            })
            console.log('data', data.length)
            resolve(data)
          } else {
            setTimeout(waitForDataCompletion, 200);
          }
        })();
      });
    },
    gotoNewways: function () {
      // location.href = "https://newways.kr/"
      window.open("https://eyeseeyou.imweb.me/", '_blank');
    },
    gotoArchive: function () {
      this.$router.push('/archive')
    },
    copyText: function () {
      var tempElem = document.createElement('textarea');
      tempElem.value = "#일어나정치해야지 #눈알감시단 #총선 #뉴웨이즈";  
      document.body.appendChild(tempElem);

      tempElem.select();
      document.execCommand("copy");
      document.body.removeChild(tempElem);

      this.hashToastItem.show();
    },
    share: async function () {
      let share_url = 'https://standup.newways.kr/'
      // const shareText = 
      //   "혹시 지금 답답하다면 같이 일어나실래요? 깜깜하기 짝이 없는 국회 밝히러 가요.\n #일어나정치해야지 #눈알감시단 #총선 #뉴웨이즈";

      const newText = '혹시 지금 답답하다면 같이 일어나실래요? 깜깜하기 짝이 없는 국회 밝히러 가요. 👉 https://standup.newways.kr/ #일어나정치해야지 #눈알감시단 #총선 #뉴웨이즈'
      const shareData = {
        title: newText,
        text: newText,
        url: share_url,
      }

      if (navigator.share) {
        await navigator.share(shareData)
      } else {
        var tempElem = document.createElement('textarea');
        tempElem.value = newText;
        document.body.appendChild(tempElem);

        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);

        this.toastItem.show();
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  color: #000;
}

.hello {
  background: #E5E5E5;
  overflow: auto;
}

.desc {
  padding-top: 20px;
  padding-bottom: 20px;

  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  background: #000000;
  color: #fff;
}

.save-image-desc {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  color: #616161;
  margin-top: 16px;
  margin-bottom: 8px;
}

.share-desc {
  font-weight: normal;
  font-size: 14px;
  color: #616161;
  padding: 10px 0px;
  /* font-size: 16px;
  line-height: 24px;
  padding: 40px 0px;
  font-weight: bold; */
}

.why-button {
  text-align: center;
  padding: 16px;

  width: 89%;
  margin: 16px 16px 8px 16px;
  font-size: 16px;
  font-weight: bold;
  background: #dae000;
  color: #000;
  border: solid 1px #000;
  border-radius: 8px;
  height: 56px;
}

.archive-button {
  text-align: center;
  padding: 16px;

  width: 89%;
  margin: 0px 16px 32px 16px;
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  color: #000;
  border: solid 1px #000;
  border-radius: 8px;
  height: 56px;
}

.share-button {
  text-align: center;
  padding: 16px;

  margin: 0px 16px 16px 16px;
  
  width: 89%;
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  color: #000;
  border: solid 1px #000;
  border-radius: 8px;
  height: 56px;
}

.my-char {
  margin: 0px 56px;
}

.my-char img{
  width: 100%;
}

.space {
  padding: 31px 0px;
  background: #E5E5E5;
}

.hash-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  padding: 12px;
}

.copy-btn {
  text-align: center;
}

.share-text {
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 14px;
  text-align: center;
  color: #000;
}

.logo {
  text-align: center;
  padding-bottom: 50px;
}

.logo img {
  width: 7rem;
}

.real-footer {
  background-color: #000;
  color: #EEEEEE;
  padding: 32px 16px;
  font-size: 14px;
}

.real-footer .support {
  font-size: 13px;
  color: #b1b1b1;
}

.creator {
  margin: 16px 0px 8px 0px;
}

.creator a {
  color: #EEEEEE;
  text-decoration: underline;
  line-height: 24px;
}

</style>
