export const data = {
    "main": [
        {'index':0, 'url': require('./assets/images/story-01.png'), 'alt': ''},
        {'index':1, 'url': require('./assets/images/story-02.png'), 'alt': ''},
        {'index':2, 'url': require('./assets/images/story-03.png'), 'alt': ''},
        {'index':3, 'url': require('./assets/images/story-04.png'), 'alt': ''},
    ],
    "grass": [
        {'index':0, 'url': require('./assets/images/grass.png'), 'alt': ''},
    ],
    "image": [
        {'index':0, 'url': require('./assets/images/back_01.png'), 'alt': ''},
        {'index':1, 'url': require('./assets/images/back_02.png'), 'alt': ''},
        {'index':2, 'url': require('./assets/images/back_03.png'), 'alt': ''},
        {'index':3, 'url': require('./assets/images/back_04.png'), 'alt': ''},
        {'index':4, 'url': require('./assets/images/back_05.png'), 'alt': ''},
    ],
    "char": [
        {'index':0, 'url': require('./assets/images/char_001.png'), 'alt': ''},
        {'index':1, 'url': require('./assets/images/char_002.png'), 'alt': ''},
        {'index':2, 'url': require('./assets/images/char_003.png'), 'alt': ''},
        {'index':3, 'url': require('./assets/images/char_004.png'), 'alt': ''},
        {'index':4, 'url': require('./assets/images/char_005.png'), 'alt': ''},
    ],
    "placard": [
        {'index':0, 'url': require('./assets/images/placard_01.png'), 'alt': ''},
        {'index':1, 'url': require('./assets/images/placard_02.png'), 'alt': ''},
        {'index':2, 'url': require('./assets/images/placard_03.png'), 'alt': ''},
        {'index':3, 'url': require('./assets/images/placard_04.png'), 'alt': ''},
        {'index':4, 'url': require('./assets/images/placard_05.png'), 'alt': ''},
    ],
    "placardm": [
        {'index':0, 'url': require('./assets/images/placard_m_01.png'), 'alt': ''},
        {'index':1, 'url': require('./assets/images/placard_m_02.png'), 'alt': ''},
        {'index':2, 'url': require('./assets/images/placard_m_03.png'), 'alt': ''},
        {'index':3, 'url': require('./assets/images/placard_m_04.png'), 'alt': ''},
        {'index':4, 'url': require('./assets/images/placard_m_05.png'), 'alt': ''},
    ],
    // "object": [
    //     {'index':0, 'url': require('./assets/images/object_00.png'), 'alt': '도시락과 맥주를 두고 싶어요'},
    //     {'index':1, 'url': require('./assets/images/object_01.png'), 'alt': '강아지와 같이 있고 싶어요'},
    //     {'index':2, 'url': require('./assets/images/object_02.png'), 'alt': '고양이와 같이 있고 싶어요'},
    //     {'index':3, 'url': require('./assets/images/object_03.png'), 'alt': '투표 용지를 두고 싶어요'},
    //     {'index':4, 'url': require('./assets/images/object_04.png'), 'alt': '농구공을 두고 싶어요'},
    //     {'index':5, 'url': require('./assets/images/object_05.png'), 'alt': '축구공을 두고 싶어요'},
    //     {'index':6, 'url': require('./assets/images/object_06.png'), 'alt': '촛불을 두고 싶어요'},
    // ],
    // "objectForSelect": [
    //     {'index':0, 'url': require('./assets/images/select_object_00.png'), 'alt': '도시락과 맥주를 두고 싶어요'},
    //     {'index':1, 'url': require('./assets/images/select_object_01.png'), 'alt': '강아지와 같이 있고 싶어요'},
    //     {'index':2, 'url': require('./assets/images/select_object_02.png'), 'alt': '고양이와 같이 있고 싶어요'},
    //     {'index':3, 'url': require('./assets/images/select_object_03.png'), 'alt': '투표 용지를 두고 싶어요'},
    //     {'index':4, 'url': require('./assets/images/select_object_04.png'), 'alt': '농구공을 두고 싶어요'},
    //     {'index':5, 'url': require('./assets/images/select_object_05.png'), 'alt': '축구공을 두고 싶어요'},
    //     {'index':6, 'url': require('./assets/images/select_object_06.png'), 'alt': '촛불을 두고 싶어요'},
    // ] 
}