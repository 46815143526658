<template>
  <div id="whole">
    <router-view />
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";

export default {
  name: 'App',
  mounted: function () {
    // console.log('app mounted', navigator.userAgent)

    // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //   // true for mobile device
    //   console.log("mobile device");
    // }else{
    //   // false for not mobile device
    //   // console.log("not mobile device");
    //   alert('PC 환경에서는 누우실 수 없어요. 모바일에서 접속해 주세요. https://standup.newways.kr/')
    // }
  }
}
</script>

<style>
body {
  /* display: flex;
  justify-content: center; */
}

#whole {
  background: #efefef;
  height: 100dvh;
}

</style>
