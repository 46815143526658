<template>
  <div class="hello">
    <div class='backgroundBottom'></div>
    
    <div class="desc">
      어떤 메시지를<br>들고 일어날까요?
    </div>
    <div>
      <splide 
        :options="splideOptions"
        ref="splide"
        @splide:moved="onChanged"
        @splide:click="onClick">
        <splide-slide
          class="mb-5"
          v-for="image of images" 
          :key="image">
          <div class='char-border' :class="currActive(image)">
            <img class="d-block w-100" :src="image.url" :alt="image.alt">
          </div>
        </splide-slide>
      </splide>
    </div>

    <button class="fixed-bottom next-button" @click="nextPage">
      일어날 준비 완료!
    </button>

    <div style="display: none;">
      <img :src="imageData" style="width:100%"/>
    </div>
    <div style="display: none;">
      <canvas id="canvas" ></canvas>
    </div>

    <section v-if="showModal">
      <PopupModal :closeBtn="false"
                  @before-close="beforeCloseModal">
      </PopupModal>
    </section>
  </div>
</template>

<script>
/*eslint no-undef: "off"*/
import { firestore } from '../main'
import { data } from '../data'
import moment from 'moment'
import PopupModal from './components/PopupModal'

export default {
  name: 'SelectObjectView',
  components: {
    PopupModal
  },
  data: () => {
    return {
      images: data.placardm,
      currIndex: 0,
      showModal: false,

      splideOptions: {
        gap    : '15px',
        arrows : false,
        padding: {
          right: '3rem',
          left : '3rem',
        },
      },

      canvas: null,
      imageData: null,
    }
  },
  mounted: function () {
    // console.log('mounted', moment().unix())
  },
  methods: {
    currActive: function (image) {
      return image.index == this.currIndex ? 'active' : ''
    },
    onChanged: function (_, newIndex) {
      this.currIndex = newIndex
    },
    onClick: function (e, index) {
      const splide = this.$refs.splide
      splide.go(index.index)
    },
    createImage: async function () {
      const imageSize = 2048;
      const canvasSize = 300;
      this.canvas = new fabric.Canvas('canvas', {
          isDrawingMode: false,
          width: canvasSize,
          height: canvasSize,
          allowTouchScrolling: false,
      });

      const backIndex = +localStorage.selectBackground
      const charIndex = +localStorage.selectChar
      const objectIndex = +localStorage.selectObject

      let imageStatus = 0
      // fabric.Image.fromURL(data.grass[0].url, (grass) => {
      //     grass.scaleX = canvasSize / imageSize
      //     grass.scaleY = canvasSize / imageSize
      //     grass.left = 0
      //     grass.top = 0
      //     this.canvas.add(grass);
      imageStatus = 1
      console.log('finish grass')
      fabric.Image.fromURL(data.image[backIndex].url, (back) => {
          back.scaleX = canvasSize / imageSize
          back.scaleY = canvasSize / imageSize
          back.left = 0
          back.top = 0
          this.canvas.add(back);
          console.log('finish back')
          imageStatus = 2

          fabric.Image.fromURL(data.char[charIndex].url, (char) => {
              char.scaleX = canvasSize / imageSize
              char.scaleY = canvasSize / imageSize
              char.left = 0
              char.top = 0
              this.canvas.add(char);
              console.log('finish char')
              imageStatus = 3

              fabric.Image.fromURL(data.placard[objectIndex].url, (ob) => {
                  // console.log('ob', ob)
                  ob.scaleX = (canvasSize / imageSize)
                  ob.scaleY = (canvasSize / imageSize)
                  // ob.left = (canvasSize / 2) - (ob.width * ob.scaleX / 2)
                  // ob.top = (canvasSize / 2) - (ob.height * ob.scaleY / 2)
                  char.left = 0
                  char.top = 0
                  this.canvas.add(ob);
                  console.log('finish ob')
                  imageStatus = 4
                  setTimeout(() => {
                    imageStatus = 5
                  }, 10)
              })
          })
      })
      // })

      console.log('imageStatus', imageStatus)

      return new Promise(resolve => setTimeout(() => {
        console.log('imageStatus', imageStatus)
        const data = this.canvas.toDataURL({
            format: 'png',
            multiplier: 2.5,
        })
        console.log('data', data.length)
        resolve(data)
      }, 3000));

      // let maxLen = 1048487;
      // let multiplier = 1.5;
      // let quality = 1;
      // return new Promise((resolve) => {
      //   (function waitForDataCompletion() {
      //     if (imageStatus == 5) { // 'dataIsReady'는 데이터가 준비되었는지 확인하는 함수여야 합니다.
      //       console.log('imageStatus', imageStatus)
      //       const data = this.canvas.toDataURL({
      //           format: 'png',
      //           multiplier: 2.5,
      //       })
      //       console.log('data', data.length)
      //       resolve(data)
      //       // if (data.length > maxLen) {
      //       //   quality -= 0.1;
      //       //   multiplier -= 0.1;
      //       //   if (quality < 0.3) {
      //       //     resolve('')
      //       //     return;
      //       //   }
      //       //   console.log('data', quality, multiplier, typeof(data), data.length)
      //       //   setTimeout(waitForDataCompletion, 1000);
      //       //   return;
      //       // }
      //       resolve(data)
      //     } else {
      //       setTimeout(waitForDataCompletion, 700);
      //     }
      //   })();
      // });
    },
    saveData: async function () {
      this.showModal = true

      const imageData = await this.createImage();
      this.imageData = imageData;

      localStorage.imageData = imageData;

      const res = await firestore.collection('test').add({
        imageData: imageData,
        background: localStorage.selectBackground,
        char: localStorage.selectChar,
        obejct: localStorage.selectObject,
        timestamp: moment().unix()
      })

      console.log('saveData', res)
      localStorage.myId = res.id;

      const doc = await firestore
                      .collection('test_count')
                      .doc('count')
                      .get();

      const count = doc.data().count + 1
      await firestore.collection('test_count').doc('count').set({count});
    },
    nextPage: async function () {
      localStorage.selectObject = this.currIndex;
      
      await this.saveData()

      this.$router.push('/archive?make=true')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  background: #000;
}

.backgroundBottom {
  position: fixed;
  width: 100%;
  height: 50%;
  background: #000;
  z-index: -10;
  bottom: 0;
}

.next-button {
  text-align: center;
  padding: 16px;

  margin: 16px;
  font-size: 16px;
  font-weight: bold;
  background: #DAE000;
  color: #000;
  border-radius: 8px;
  height: 56px;
}

.char-border {
  width: 100%;
  height: 250px;
  border: 0px solid #000;
  border-radius: 8px;
  background: #fff;
      
  vertical-align: middle;
  display: table-cell;
}

.char-border.active {
  border: 3px solid #000;
}


.desc {
  padding-top: 47px;
  padding-bottom: 32px;

  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #fff;
}

.char-border img{
  transform: rotate(0deg);
}
</style>
